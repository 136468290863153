import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import PostGrid from '../components/postgrid'

interface ITagProps {
    data: {
        ghostTag: IGhostTag
        allGhostPost: {
            posts: {
                post: IGhostPost
            }[]
        }
    },
    location: string
}

export default function Tag({ data, location }: ITagProps) {
    return (
        <Layout>
            <Helmet title={`${data.ghostTag.name} Articles`} />
            <h1 className="text-4xl font-bold mb-8">{data.ghostTag.name} Articles</h1>
            <section>
                <PostGrid posts={data.allGhostPost.posts} featureTopPost={false} />
            </section>
        </Layout>
    )
}

export const tagQuery = graphql`
    query GhostTagQuery($slug: String!) {
        ghostTag(slug: { eq: $slug }) {
            name
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {tags: {elemMatch: {slug: {eq: $slug}}}},
        ) {
            posts: edges {
                post: node {
                    id
                    title
                    slug
                    excerpt
                    published_at(formatString: "MMMM DD, YYYY")
                    feature_image
                    primary_author {
                        name
                    }
                    tags {
                        name
                        slug
                        visibility
                    }
                }
            }
        }
    }
`